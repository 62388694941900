
import utils from '../../util';

export default {
  name: 'projectVersion',
  computed: {
    buildInfo(): string {
      if (process && process.env && process.env.VUE_APP_BUILD_VERSION && process.env.VUE_APP_BUILD_VERSION.length > 8) {
        return `v.1.${process.env.VUE_APP_BUILD_VERSION.substr(3, 6)}`;
      }
      return 'n/a';
    },
    sources(): string {
      let sources = '';

      // Replace feeds/sources from Dashboard BE
      const sourceReplaceMap = {
        TVSQUARED: 'InnovidXP',
      };

      if (this.feedSources.length > 0) {
        sources = this.feedSources.map(feed => sourceReplaceMap[feed] || feed).join(', ');
      }
      if (this.layout) {
        if (sources.length) {
          sources += ' | ';
        }
        sources += `${this.layout.type} v${this.layout.Version}`;
      }
      return sources;
    },
    isSummaryPage(): boolean {
      return this.$route?.query?.tab === 'summary';
    },
    feedSources(): Array<string> {
      if (this.isExporting || this.isSummaryPage) {
        return [];
      }
      const feeds = utils.feedSources(this);
      return feeds;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    layout(): any {
      return this.$store.state?.customer?.dynamicLayout?.layoutCustomizations;
    },
  },
};
